import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/firestore';
import { isProduction, isDemo } from './functions';

const SandboxConfig = {
  apiKey: 'AIzaSyCLzmBaCYCLqchEIhExTlM_pNaVy-amVt4',
  authDomain: 'jyba-sandbox.firebaseapp.com',
  databaseURL: 'https://jyba-sandbox.firebaseio.com',
  projectId: 'jyba-sandbox',
  storageBucket: 'jyba-sandbox.appspot.com',
  messagingSenderId: '74537544877',
};

const ProductionConfig = {
  apiKey: "AIzaSyAQgzBmya4WBhyP9kzWMmu5CmJXVBobt34",
  authDomain: "jyba-production-f99fc.firebaseapp.com",
  databaseURL: "https://jyba-production-f99fc.firebaseio.com",
  projectId: "jyba-production",
  storageBucket: "jyba-production.appspot.com",
  messagingSenderId: "503684499523",
  appId: "1:503684499523:web:ad203da4a821ce98"
};

const config = (isProduction() && !isDemo()) ? ProductionConfig : SandboxConfig;

firebase.initializeApp(config);
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const auth = firebase.auth();
export const storage = firebase.storage();
debugger;
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : () => {console.log('[SW] Service Worker not available.')};
export const firestore = firebase.firestore();
export default firebase;
