import User from '../services/User';
import Trip from '../services/Trip';

export const GET_PROFILE_INFORMATION = 'GET_PROFILE_INFORMATION';
export const DELIVERED_PROFILE_INFORMATION = 'DELIVERED_PROFILE_INFORMATION';
export const GET_USER_TRIPS = 'GET_USER_TRIPS';
export const DELIVERED_USER_TRIPS = 'DELIVERED_USER_TRIPS';

export function getProfileInfo() {
  return {
    type: GET_PROFILE_INFORMATION,
  };
}

export function deliveredProfileInfo(session) {
  return {
    type: DELIVERED_PROFILE_INFORMATION,
    session,
  };
}

export function getUserTrips() {
  return {
    type: GET_USER_TRIPS,
  };
}

export function deliveredUserTrips(trips) {
  return {
    type: DELIVERED_USER_TRIPS,
    trips,
  };
}

export function getProfileInformation() {
  return (dispatch) => {
    dispatch(getProfileInfo());
    const session = User.getSession();
    dispatch(getUserTrips());
    let allTrips = [];
    Trip.getUserTrips(session.uid).then((trips) => {
      trips.forEach((trip)=> {
        const tripData = trip.data();
        allTrips.push({...tripData});
      });
      dispatch(deliveredUserTrips(allTrips));
      dispatch(deliveredProfileInfo(session));
    });
  };
}