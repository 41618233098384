import User from '../services/User';
import { validateUser } from '../helpers/functions';
import firebase from '../helpers/firebase';

export const SUBMITTING_INFORMATION = 'SUBMITTING_INFORMATION';
export const SUBMITTED_INFORMATION = 'SUBMITTED_INFORMATION';
export const SUBMITTED_ERROR = 'SUBMITTED_ERROR';
export const GET_INFORMATION = 'GET_INFORMATION';
export const DELIVERED_INFORMATION = 'DELIVERED_INFORMATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SUBMITTING_DOCUMENTS = 'SUBMITTING_DOCUMENTS';
export const SUBMITTED_DOCUMENTS = 'SUBMITTED_DOCUMENTS';
export const SUBMITTING_VEHICLE_PHOTO = 'SUBMITTING_VEHICLE_PHOTO';
export const SUBMITTED_VEHICLE_PHOTO = 'SUBMITTED_VEHICLE_PHOTO';
export const SUBMISSION_FINISHED = 'SUBMITTED_FINISHED';
export const SUBMITTED_PHONE = 'SUBMITTED_PHONE';
export const UPDATED_PICTURE = 'UPDATED_PICTURE';
export const SUBMITTING_BANNER_PHOTO = 'SUBMITTING_BANNER_PHOTO';
export const SUBMITTED_BANNER_PHOTO = 'SUBMITTED_BANNER_PHOTO';

require('firebase/auth');

export function submitInfo(information) {
  return {
    type: SUBMITTING_INFORMATION,
    information,
  };
}

export function getInfo() {
  return {
    type: GET_INFORMATION,
  };
}

export function deliveredInfo(session) {
  return {
    type: DELIVERED_INFORMATION,
    session,
  };
}

export function submittedInformation(result) {
  return {
    type: SUBMITTED_INFORMATION,
    result,
  };
}

export function showNotification() {
  return {
    type: SHOW_NOTIFICATION,
  };
}

export function hideNotification() {
  return {
    type: HIDE_NOTIFICATION,
  };
}

export function submitError(error) {
  return {
    type: SUBMITTED_ERROR,
    error,
  };
}

export function submitDocs(documents) {
  return {
    type: SUBMITTING_DOCUMENTS,
    documents,
  };
}

export function submittedDocs(documents) {
  return {
    type: SUBMITTED_DOCUMENTS,
    documents,
  };
}

export function submissionFinish(information) {
  return {
    type: SUBMISSION_FINISHED,
    information,
  };
}

export function submittedPhone() {
  return {
    type: SUBMITTED_PHONE,
  };
}

export function updatedUserPicture(user) {
  return {
    type: UPDATED_PICTURE,
    user,
  }
}

export function submittingVehiclePhoto() {
  return {
    type: SUBMITTING_VEHICLE_PHOTO,
  };
}

export function submittedVehiclePhoto() {
  return {
    type: SUBMITTED_VEHICLE_PHOTO,
  };
}

export function submittingBannerPhoto() {
  return {
    type: SUBMITTING_BANNER_PHOTO,
  }
}

export function submittedBannerPhoto() {
  return {
    type: SUBMITTED_BANNER_PHOTO,
  }
}

export function submitInformation(information) {
  return (dispatch) => {
    const type = information.type;

    const user = User.getSession();
    const item = {
      ...user,
      ...information,
    };

    const auth = firebase.auth(); // eslint-disable-line
    let emailChanged = false;

    switch (type) {
      case 'information':
        // Only email generated accounts can change their email
        if (user.email !== information.email && user.provider === 'password') {
          emailChanged = true;
          item.emailVerified = false;
          validateUser(item);
        }
        dispatch(submitInfo(item)); break;
      case 'preferences':
        dispatch(submitInfo(item)); break;
      case 'about':
        dispatch(submitInfo(item)); break;
      default:
        dispatch(submitInfo(item)); break;
    }

    User.update(item).then(() => {
      User.setSession(item);

      switch (type) {
        case 'information':
          dispatch(checkUserEmailChanged(information,emailChanged,item));
          dispatch(submittedInformation(item));
          break;
        case 'preferences':
          dispatch(submittedInformation(item)); break;
        case 'about':
          dispatch(submittedInformation(item)); break;
        default:
          dispatch(submittedInformation(item)); break;
      }
    }).catch((error) => {
      dispatch(submitError(error));
    });
  };
}

export function handleSubmission(document, user) {
  return (dispatch) => {
    dispatch(submittedDocs(document));
    User.update(user).then(() => {
      User.setSession(user);
      dispatch(submitInfo(user));
      dispatch(submissionFinish(user));
      validateUser(user);
    });
  };
}

export function submitDocuments(docs) {
  return (dispatch) => {
    const { licenseFile, idFile } = docs;
    let user = User.getSession();

    // Both documents are set so a promise to submitting both documents is needed
    if (licenseFile && idFile) {
      const setLicenseFile = User.setDocument('license', licenseFile);
      const setIdFile = User.setDocument('id', idFile);
      Promise.all([setLicenseFile, setIdFile]).then((results) => {
        dispatch(submitDocs({ licenseFile, idFile }));
        if (results[0].state === 'success' && results[1].state === 'success') {
          const documents = {
            license: results[0].metadata.fullPath,
            id: results[1].metadata.fullPath,
          };
          user = {
            ...user,
            documents,
          };
          dispatch(handleSubmission(documents, user));
        } else {
          dispatch(submitError(results[0]));
          dispatch(submitError(results[1]));
        }
      }).catch((error) => {
        dispatch(submitError(error));
      });
    } else if (licenseFile) {
      dispatch(submitDocs({ licenseFile }));
      const { id } = user.documents;
      User.setDocument('license', licenseFile).then((snapshot) => {
        if (snapshot.state === 'success') {
          const document = { license: snapshot.metadata.fullPath };
          user = {
            ...user,
            documents: {
              id,
              ...document,
            },
          };
          dispatch(handleSubmission(document, user));
        } else {
          dispatch(submitError(snapshot));
        }
      }).catch((error) => {
        dispatch(submitError(error));
      });
    } else if (idFile) {
      dispatch(submitDocs({ idFile }));
      const { license } = user.documents;
      User.setDocument('id', idFile).then((snapshot) => {
        if (snapshot.state === 'success') {
          const document = { id: snapshot.metadata.fullPath };
          user = {
            ...user,
            documents: {
              license,
              ...document,
            },
          };
          dispatch(handleSubmission(document, user));
        } else {
          dispatch(submitError(snapshot));
        }
      }).catch((error) => {
        dispatch(submitError(error));
      });
    }
  };
}

export function getInformation() {
  return (dispatch) => {
    dispatch(getInfo());
    const session = User.getSession();
    dispatch(deliveredInfo(session));
  };
}

export function show() {
  return (dispatch) => {
    dispatch(showNotification());
  };
}

export function hide() {
  return (dispatch) => {
    dispatch(hideNotification());
  };
}

export function submitPhone() {
  return (dispatch) => {
    dispatch(submittedPhone());
  }
}

export function updateUserPicture(photoFile, type){
  return (dispatch) => {
    const user = User.getSession();
    switch (type) {
      case 'banner':
        dispatch(updatedUserPicture({...user, banner: photoFile})); break;
      case 'photo':
        dispatch(updatedUserPicture({...user, photo: photoFile})); break;
      default:
        dispatch(updatedUserPicture({...user, photo: photoFile})); break;
    }
  }
}

export function checkUserEmailChanged(information, emailChanged, item) {
  return (dispatch) => {
    const newUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential( // eslint-disable-line
      newUser.email,
      information.password,
    );

    if (emailChanged) {
      newUser.sendEmailVerification().then(() => {
        newUser.reauthenticateAndRetrieveDataWithCredential(credential).then(() => {
          newUser.updateEmail(information.email);
          validateUser(item);
        });
      });
    }
  }
}

export function uploadVehiclePhoto() {
  return (dispatch) => {
    dispatch(submittingVehiclePhoto());
  }
}

export function finishUploadVehiclePhoto() {
  return (dispatch) => {
    dispatch(submittedVehiclePhoto());
  }
}

export function uploadBannerPhoto() {
  return (dispatch) => {
    dispatch(submittingBannerPhoto());
  }
}

export function finishUploadBannerPhoto() {
  return (dispatch) => {
    dispatch(submittedBannerPhoto());
  }
}