import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import Card from '@material-ui/core/Card/Card';
import Paper from '@material-ui/core/Paper/Paper';
import { Link } from 'react-router-dom';

const Terms = () => {
  const url = (
    <a href="https://www.demo.jyba.app">https://www.demo.jyba.app</a>);

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center">
        <Card id="legal">
          <Paper className="text">
            <h1>Términos y Condiciones</h1>
            <p>El siguiente documento establece la forma adecuada en la que
              habrán de utilizarse las plataformas de Jyba así como los términos
              a los que se sujetarán tanto el sitio web como la Aplicación
              (App), ante cualquier persona que hago uso del mismo, ya sea
              Usuario o Tercero.</p>
            <h3>Con respecto al acceso al sitio</h3>
            <p>El sitio web de Jyba tiene la siguiente dirección: {url} , ésta
              te re-direccionará a la App con la que podrás contar en tus
              aparatos electrónicos con acceso a Internet a través de un
              Navegador. </p>
            <p>Cualquier persona que tenga acceso a la dirección anterior y de
              “clic” sobre el mismo, se considerará Usuario de la misma y se
              diferenciará al Usuario que simplemente navegue por el sitio web,
              al Usuario que efectivamente tenga una cuenta validada con
              Jyba.</p>
            <p>Ambos tipos de Usuarios están sujetos a los términos y
              condiciones que aquí se indiquen y al navegar sobre la dirección
              de Jyba se presumirá que entienden y aceptan lo que aquí se
              inserte y convenga. </p>
            <h3>Con respecto al contenido y su uso</h3>
            <p>Jyba te ofrece una navegación ágil, visual y sencilla. El Usuario
              que navegue por el sitio web y/o aplicación (App) sin tener una
              cuenta validada, podrá visualizar el concepto esencial de Jyba,
              así como lo que se ofrece y el contacto del mismo, otorgándole la
              posibilidad al Usuario de que pueda registrarse y crear una cuenta
              validada, ya sea de manera personalizada o con cuenta Google y/o
              Facebook.</p>
            <p>El Usuario con cuenta validada por Jyba tendrá acceso a otras
              pantallas del sitio web en las cuales se otorgará una serie de
              opciones para personalizar tu perfil y conocer mejor a las
              personas con las que podrías llegar a viajar. Así mismo, Jyba
              ofrece una sección de valoración del conductor, en una escala del
              1 a 5 estrellas que podrás utilizar de la manera más profesional y
              honesta, para que gente como tú pueda evaluar sus opciones y
              ofrezca a Jyba una retroalimentación de las personas que hacen uso
              del mismo. </p>
            <p>El Usuario podrá hacer uso del contenido de la página de la
              manera más prudente posible y de acuerdo a lo que se indique en
              este documento así como en las leyes correspondientes. </p>
            <p>Estará estrictamente prohibido, en la sección de fotografía,
              subir imágenes o fotos ofensivas, imprudentes y/o violentas, la
              persona que entre dentro de este supuesto, será dada de baja por
              Jyba automáticamente, en cumplimiento con las presentes políticas.
              Lo mismo aplicará para la sección del chat y los comentarios. </p>
            <h3>Con respecto a las modificaciones del sitio web o la App</h3>
            <p>Jyba tendrá la prerrogativa de hacerle modificaciones a la imagen
              y al contenido del sitio web y su aplicación (App), las veces que
              así lo requiera o guste sin tener que dar previo aviso a sus
              Usuarios o Terceras personas; lo anterior, no implica que los
              usuarios no puedan enviar recomendaciones u opiniones al respecto,
              éstas se recibirán y se analizarán por las personas
              correspondientes y en medida de lo indispensable, se dará
              respuesta a dichas solicitudes y/u observaciones. Es importante
              señalar que lo anterior, no significa que lo que se resuelva o
              conteste al respecto, sea vinculativo de alguna manera, sino que
              será meramente informativo y consultivo. </p>
            <h3>Con respecto al Hardware y Software del Usuario</h3>
            <p>Jyba no será responsable por los problemas técnicos o
              desperfectos que se llegaren a presentar en el uso del sitio web
              y/o aplicación (App) con lo relativo a la capacidad y velocidad
              del Internet de cada Usuario, la carga de la página, y cualquier
              otra cuestión técnica particular. Por otro lado, cualquier
              desperfecto que el sitio web y/o aplicación (App) presente y que
              efectivamente sea competencia y responsabilidad de Jyba, se
              presentará aviso de mantenimiento sobre la misma página, de forma
              contraria, Jyba se deslindará de responsabilidades no aplicables
              al mismo. </p>
            <h3>Con respecto a tus compromisos con Jyba</h3>
            <p>Jyba maneja con datos personales y sensibles ya que se
              necesitarán para poder cumplir con nuestros objetivos,
              principalmente el de brindarte mayor seguridad al viajar, estos
              datos serán resguardados y podrás revisar las Políticas de
              Privacidad para mayor información al respecto. En el mismo orden
              de ideas, necesitamos que el nombre que establezcas como
              predeterminado, concuerde con el que aparecerá en tu IFE/INE o
              Licencia de Conducir, ya que esto ayudará a mantener nuestro
              objetivo que a final de cuentas te beneficia a ti como
              Usuario. </p>
            <p>Tendrás que ser lo más honesto al vaciar tu información personal
              y de viaje en el sitio web y la aplicación (App), ya que las demás
              personas buscarán dentro de los Usuarios validados un acompañante
              con sus mismos intereses y preferencias. Cabe resaltar que el
              mismo sitio web y la aplicación (App), te darán la opción de
              elegir lo que se ve públicamente, por lo que, lo que el Usuario
              decida hacer público, aún cuando se trate de datos personales y
              sensibles, quedará bajo su más estricta y única
              responsabilidad. </p>
            <p>Queda estrictamente prohibido hacer un mal uso del sitio web y/o
              la aplicación (App) de Jyba, así como buscar apropiarse de la
              información aquí contenida. Todos los derechos de propiedad
              intelectual pertenecen a Jyba y no a los Usuarios, a menos que se
              trate de fotografías y/o medios visuales subidos por el mismo
              Usuario. </p>
            <p>Jyba cumple con el filtro de solicitar la Licencia de Conducir a
              los conductores para contar con una forma más certera de comprobar
              si la persona está en posibilidades y condiciones de manejar. Por
              lo mismo, Jyba no se hace responsable en caso de que un conductor
              esté imposibilitado para manejar y al mismo tiempo realice un
              viaje bajo estas condiciones, en razón de que presentaré alguna
              Licencia falsa o con datos inexistentes, o que al momento del
              viaje se presentaren circunstancias que no pueden estar bajo
              control de Jyba. </p>
            <h3>De la desactivación de cuenta</h3>
            <p>Tú como Usuario podrás desactivar tu cuenta en cualquier momento
              y por tus propios motivos. Al hacer esto, Jyba te solicitará
              alguna explicación breve del por qué tomarás dicha decisión, sin
              que esto implique alguna otra cosa mas que retroalimentación para
              Jyba. </p>
            <p>Una vez desactivada tu cuenta, los datos personales y/o sensibles
              que hayas compartido con Jyba permanecerán en nuestra base de
              datos por un término de 3 meses para en caso de que autoridad
              competente requiera dicha información. Después de pasado este
              termino, tus datos quedarán completamente eliminados y nosotros
              como Jyba, deslindados de responsabilidad ante ellos por el mal
              uso de los mismos. </p>
            <h3>En caso de conflicto entre Jyba y el Usuario</h3>
            <p>Los términos de uso aquí planteados son un acuerdo válido entre
              Jyba y el Usuario. Por lo anterior, es que en caso de cualquier
              disputa o conflicto entre las partes, la jurisdicción
              corresponderá a los Tribunales Competentes de Chihuahua,
              Chihuahua, México, a menos que la Ley correspondiente indique
              distinto. </p>
            <strong><Link to="/">Volver a página de inicio</Link></strong>
          </Paper>
        </Card>
      </Grid>
    </Fragment>
  );
};

export default Terms;
