import React from 'react'; // eslint-disable-line
import LogRocket from 'logrocket';
import Analytics from 'react-ga';
import Grid from '@material-ui/core/Grid/Grid';
import User from '../services/User';
import firebase from './firebase';
import { INCOMPLETE_USER, passenger, VALID_USER } from './constants';
import moment from "moment";

export function logWithUserInformation() {
  if (isProduction()) { // eslint-disable-line
    const user = User.getSession();
    const { uid, name, email } = user;
    Analytics.set({ userId: uid, name: `${name.firstName} ${name.lastName}`, email });
    LogRocket.identify(uid, {
      name: `${name.firstName} ${name.lastName}`,
      email,
    });
  }
}

export function isProduction() {
  return (!process.env.NODE_ENV || process.env.NODE_ENV === 'production');
}

export function isDemo() {
  return window.location.hostname === 'demo.jyba.app';
}

export function isUserLoggedIn() {
  return !!User.getSession();
}

export function isUserPendingNotifications() {
  const user = User.getSession();

  return !user.messageToken;
}

export function isUserPassenger(user, passengers) {
  const ids = Object.keys(passengers);
  return ids.some((id) => {
    return user.uid === id;
  });
}

export function isUserValid(user) {
  const { isVerified } = user;
  switch (isVerified) {
    case INCOMPLETE_USER:
      return (<Grid item className="validation user-incomplete">Validación Incompleta</Grid>);
    case VALID_USER:
      return (<Grid item className="validation complete">Usuario Validado</Grid>);
    default:
      return (<Grid item className="validation not-valid">Usuario No Validado</Grid>);
  }
}

export function getFreeSeats(trip) {
  const { passengers, seats } = trip;
  let free = seats;
  const ids = Object.keys(passengers);
  ids.forEach((id) => {
    if (passengers[id].status === passenger.ACCEPTED) {
      free -= 1;
    }
  });

  return free;
}

export async function askForPermissionToReceiveNotifications() {
  if ('serviceWorker' in navigator) {
    try {
      console.log('[SW] Request Permission'); // eslint-disable-line no-console
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();

      return token;
    } catch (error) {
      console.log('[SW] Notifications Not Accepted', error.message); // eslint-disable-line no-console
      return false;
    }
  }
}

export function isMobile() {
  const properties = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  return properties.width < 600;
}

export function getProfilePicture(user) {
  return user.photo ? user.photo : '/src/static/img/profile.png';
}

export function validateUser(user) {
  const validatedUser = user;

  if (validatedUser.isVerified !== 2) {
    if (validatedUser.phoneVerified && validatedUser.emailVerified &&
        validatedUser.documents.id && validatedUser.documents.license) {
      validatedUser.isVerified = 2;
    } else if (validatedUser.phoneVerified || validatedUser.emailVerified ||
        validatedUser.documents.id || validatedUser.documents.license) {
      validatedUser.isVerified = 1;
    }
    User.update(validatedUser);
    User.setSession(validatedUser);
  } else {
    User.setSession(validatedUser);
  }
}

export function totalPassengers(passengers = {}) {
  return passengers ? Object.keys(passengers).filter(id =>  passengers[id].status === passenger.ACCEPTED).length : 0;
}

export function generateRandomTrip(origin, destination, date) {
  let trip = {origin, destination};
  const males = ["Juan", "Jose", "Jose Luis", "Francisco", "Antonio", "Jesús", "Miguel Ángel", "Pedro", "Alejandro", "Manuel"];
  const females = ["Guadalupe", "María Guadalupe", "Margarita", "Maria del Carmen", "María", "Verónica", "Rosa", "Elena", "Alicia", "Teresa"];
  const lastNames = ["Martínez", "Peña", "Pérez", "Sáenz", "García", "Torres", "Corona", "Castillo", "Cruz", "Gutiérrez"];
  const malePics = ['https://firebasestorage.googleapis.com/v0/b/jyba-production.appspot.com/o/static%2FPerfil%20Hombre.jpg?alt=media&token=fbb57169-746e-4f3e-90e5-e5df69de543f',
    'https://firebasestorage.googleapis.com/v0/b/jyba-production.appspot.com/o/static%2FPerfil%20Hombre2.jpg?alt=media&token=cb4e0305-9475-4bdd-b8b5-8ea29b9f0b4f',
    'https://firebasestorage.googleapis.com/v0/b/jyba-production.appspot.com/o/static%2FPerfil%20Hombre3.jpg?alt=media&token=ab1447f6-d7db-48f9-96e8-b70bcd3c4032',
    'https://firebasestorage.googleapis.com/v0/b/jyba-production.appspot.com/o/static%2Fprofile.jpg?alt=media&token=8f540025-0f14-40cb-a274-c74af39b092d'];
  const femalePics = ['https://firebasestorage.googleapis.com/v0/b/jyba-production.appspot.com/o/static%2FPerfil%20Mujer.jpg?alt=media&token=1bb1aa29-9d73-49b8-8b4e-29d3c3366977',
    'https://firebasestorage.googleapis.com/v0/b/jyba-production.appspot.com/o/static%2FPerfil%20Mujer2.jpg?alt=media&token=2b1b4fc9-2cb0-4858-9ea9-123cfee5154d',
    'https://firebasestorage.googleapis.com/v0/b/jyba-production.appspot.com/o/static%2FPerfil%20Mujer3.jpg?alt=media&token=671cca68-6417-49d5-affa-ac2ca89e1e01',
    'https://firebasestorage.googleapis.com/v0/b/jyba-production.appspot.com/o/static%2Fprofile.jpg?alt=media&token=8f540025-0f14-40cb-a274-c74af39b092d'];
  let cost, firstName, isVerified, photo, passengers = {};
  const hour = getRndInteger(6, 18,1);
  const minutes = getRndInteger(0,59,1);
  const _date = moment(date).set({'hour': hour, 'minute': minutes});
  const seats = getRndInteger(2,4,1);
  const rate = getRndInteger(4,5,1);
  const gender = (getRndInteger(1,2,1) === 1) ? 'male' : 'female';
  const lastName = lastNames[getRndInteger(0,10)];
  const nameR = getRndInteger(0,10);
  const photoR = getRndInteger(0,4);
  if(photoR === 3) {
    photo = malePics[photoR];
    isVerified = 1;
  } else {
    isVerified = 2;
    gender === 'male' ? photo = malePics[photoR] : photo = femalePics[photoR];
  }

  gender === 'male' ? firstName =  males[nameR] : firstName = females[nameR];

  if (origin === 'Juárez' || destination === 'Juárez') {
    cost = getRndInteger(250,350,1);
  } else if (origin === 'Delicias' || destination === 'Delicias') {
    cost = getRndInteger(75,150, 1);
  } else if (origin === 'Casas Grandes' || destination === 'Casas Grandes') {
    cost = getRndInteger(180,250, 1);
  }

  for(let i=0; i<seats; i++) {
    const pass = {
      id: i,
      name: '',
      phone: '',
      photo: '',
      status: 1,
    };
    passengers = {...passengers, [i]: pass, }
  }

  function yesNo() {
    return getRndInteger(1,2, 1) === 1 ? 'yes' :'no';
  }

  function getRndInteger(min, max, sum = 0) {
    return Math.floor(Math.random() * (max - min + sum) ) + min;
  }

  trip = {...trip,
      car: 0,
      cost,
      date: _date,
      details: '',
      driver: {
        isVerified,
        name: {
          firstName,
          lastName,
        },
        photo,
        preferences: {
          animals: yesNo(),
          eat: yesNo(),
          kids: yesNo(),
          music: yesNo(),
          smoke: yesNo(),
          talk: yesNo(),
        },
        rate,
        userId: 'a',
      },
      passengers,
      seats,
      status: 0,
  };

  return trip;
}