export const COUNTRIES = [
  { label: 'México' },
  { label: 'Estados Unidos' },
].map(country => ({
  value: country.label,
  label: country.label,
}));

export const passenger = {
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2,
};

export const tripStatus = {
  AVAILABLE: 0,
  IN_PROGRESS: 1,
  FINISHED: 2,
  CANCELED: 3,
};

export const INCOMPLETE_USER = 1;
export const VALID_USER = 2;

const allCities = [
  'Juárez',
  'Chihuahua',
  'Cuauhtémoc',
  'Delicias',
  'Hidalgo del Parral',
  'Casas Grandes',
  'Guadalupe y Calvo',
  'Guachochi',
  'Camargo',
  'Meoqui',
  'Jiménez',
  'Guerrero',
  'Saucillo',
  'Madera',
  'Bocoyna',
  'Ojinaga',
  'Ascención',
  'Namiquipa',
  'Buenaventura',
  'Aldama',
  'Urique',
  'Balleza',
  'Rosales',
  'Batopilas',
  'Ahumada',
  'Janos',
  'Aquiles Serdán',
  'Santa Bárbara',
  'Guazapares',
  'Carichí',
  'Gómez Farías',
  'Chínipas',
  'Allende',
  'Morelos',
  'Uruachi',
  'Riva Palacio',
  'Ocampo',
  'Ignacio Zaragoza',
  'Guadalupe',
];

export const cities = allCities.sort();

export const brandOptions = [
  { value: 'nissan', label: 'Nissan'},
  { value: 'mazda', label: 'Mazda'},
  { value: 'toyota', label: 'Toyota'},
  { value: 'chevrolet', label: 'Chevrolet'},
  { value: 'chrysler', label: 'Chrysler'},
  { value: 'dodge', label: 'Dodge'},
  { value: 'fiat', label: 'Fiat'},
  { value: 'ford', label: 'Ford'},
  { value: 'gmc', label: 'GMC'},
  { value: 'honda', label: 'Honda'},
  { value: 'acura', label: 'Acura'},
  { value: 'hyundai', label: 'Hyundai'},
  { value: 'jeep', label: 'Jeep'},
  { value: 'kia', label: 'Kia'},
  { value: 'mitsubishi', label: 'Mitsubishi'},
  { value: 'peugeot', label: 'Peugeot'},
  { value: 'renault', label: 'Renault'},
  { value: 'seat', label: 'Seat'},
  { value: 'subaru', label: 'Subaru'},
  { value: 'suzuki', label: 'Suzuki'},
  { value: 'toyota', label: 'Toyota'},
  { value: 'volkswagen', label: 'Volkswagen'},
];

export const modelOptions = [
  { link: 'chevrolet', value: 'beat hatchback', label: 'Beat Hatchback'},
  { link: 'chevrolet', value: 'beat notchback', label: 'Beat Notchback'},
  { link: 'chevrolet', value: 'spark', label: 'Spark'},
  { link: 'chevrolet', value: 'aveo', label: 'Aveo'},
  { link: 'chevrolet', value: 'cavalier', label: 'Cavalier'},
  { link: 'chevrolet', value: 'trax', label: 'Trax'},
  { link: 'chevrolet', value: 'equinox', label: 'Equinox'},
  { link: 'chevrolet', value: 'blazer', label: 'Blazer'},
  { link: 'chevrolet', value: 'traverse', label: 'Traverse'},
  { link: 'chevrolet', value: 'tahoe', label: 'Tahoe'},
  { link: 'chevrolet', value: 'suburban', label: 'Suburban'},
  { link: 'chevrolet', value: 'tornado', label: 'Tornado'},
  { link: 'chevrolet', value: 'silverado', label: 'Silverado'},
  { link: 'chevrolet', value: 'colorado', label: 'Colorado'},
  { link: 'chevrolet', value: 'cheyenne', label: 'Cheyenne'},
  { link: 'chevrolet', value: 'camaro', label: 'Camaro'},
  { link: 'chevrolet', value: 'stingray', label: 'Stingray'},
  { link: 'chevrolet', value: 'corvette', label: 'Corvette'},
  { link: 'chevrolet', value: 'volt', label: 'Volt'},
  { link: 'chevrolet', value: 'bolt ev', label: 'Bolt EV'},
  { link: 'chrysler', value: 'pacifica', label: 'Pacifica'},
  { link: 'chrysler', value: '300', label: '300'},
  { link: 'dodge', value: 'challenger', label: 'Challenger'},
  { link: 'dodge', value: 'charger', label: 'Charger'},
  { link: 'dodge', value: 'durango', label: 'Durango'},
  { link: 'dodge', value: 'attitude', label: 'Attitude'},
  { link: 'dodge', value: 'vision', label: 'Vision'},
  { link: 'dodge', value: 'neon', label: 'Neon'},
  { link: 'dodge', value: 'journey', label: 'Journey'},
  { link: 'dodge', value: 'grand caravan', label: 'Grand Caravan'},
  { link: 'fiat', value: 'mobi', label: 'Mobi'},
  { link: 'fiat', value: 'uno', label: 'Uno'},
  { link: 'fiat', value: 'palio adventure', label: 'Palio Adventure'},
  { link: 'fiat', value: 'fiat 500l', label: 'Fiat 500L'},
  { link: 'ford', value: 'figo', label: 'Figo'},
  { link: 'ford', value: 'fiesta', label: 'Fiesta'},
  { link: 'ford', value: 'focus', label: 'Focus'},
  { link: 'ford', value: 'fusion', label: 'Fusion'},
  { link: 'ford', value: 'mustang', label: 'Mustang'},
  { link: 'ford', value: 'ecosport', label: 'Ecosport'},
  { link: 'ford', value: 'escape', label: 'Escape'},
  { link: 'ford', value: 'edge', label: 'Edge'},
  { link: 'ford', value: 'explorer', label: 'Explorer'},
  { link: 'ford', value: 'expedition', label: 'Expedition'},
  { link: 'ford', value: 'ranger', label: 'Ranger'},
  { link: 'ford', value: 'lobo', label: 'Lobo'},
  { link: 'ford', value: 'f-150', label: 'F-150'},
  { link: 'ford', value: 'f-250', label: 'F-250'},
  { link: 'ford', value: 'raptor', label: 'Raptor'},
  { link: 'ford', value: 'gt', label: 'GT'},
  { link: 'gmc', value: 'sierra', label: 'Sierra'},
  { link: 'gmc', value: 'terrain', label: 'Terrain'},
  { link: 'gmc', value: 'acadia', label: 'Acadia'},
  { link: 'honda', value: 'hybrid insight', label: 'Hybrid Insight'},
  { link: 'honda', value: 'city', label: 'City'},
  { link: 'honda', value: 'civic', label: 'Civic'},
  { link: 'honda', value: 'accord', label: 'Accord'},
  { link: 'honda', value: 'fit', label: 'Fit'},
  { link: 'honda', value: 'br-v', label: 'BR-V'},
  { link: 'honda', value: 'hr-v', label: 'HR-V'},
  { link: 'honda', value: 'cr-v', label: 'CR-V'},
  { link: 'honda', value: 'odyssey', label: 'Odyssey'},
  { link: 'acura', value: 'ilx', label: 'ILX'},
  { link: 'acura', value: 'tlx', label: 'TLX'},
  { link: 'acura', value: 'rdx', label: 'RDX'},
  { link: 'acura', value: 'mdx', label: 'MDX'},
  { link: 'acura', value: 'nsx', label: 'NSX'},
  { link: 'hyundai', value: 'elantra', label: 'Elantra'},
  { link: 'hyundai', value: 'grand i10', label: 'Grand i10'},
  { link: 'hyundai', value: 'accent', label: 'Accent'},
  { link: 'hyundai', value: 'ioniq', label: 'Ioniq'},
  { link: 'hyundai', value: 'tucson', label: 'Tucson'},
  { link: 'hyundai', value: 'creta', label: 'Creta'},
  { link: 'hyundai', value: 'santa fe', label: 'Santa Fe'},
  { link: 'jeep', value: 'grand cherokee', label: 'Grand Cherokee'},
  { link: 'jeep', value: 'wrangler', label: 'Wrangler'},
  { link: 'jeep', value: 'renegade', label: 'Renegade'},
  { link: 'jeep', value: 'compass', label: 'Compass'},
  { link: 'jeep', value: 'cherokee', label: 'Cherokee'},
  { link: 'kia', value: 'soul', label: 'Soul'},
  { link: 'kia', value: 'forte', label: 'Forte'},
  { link: 'kia', value: 'optima', label: 'Optima'},
  { link: 'kia', value: 'sportage', label: 'Sportage'},
  { link: 'kia', value: 'sedona', label: 'Sedona'},
  { link: 'kia', value: 'sorento', label: 'Sorento'},
  { link: 'kia', value: 'niro', label: 'Niro'},
  { link: 'kia', value: 'stinger', label: 'Stinger'},
  { link: 'kia', value: 'rio', label: 'Rio'},
  { link: 'mazda', value: '2', label: '2'},
  { link: 'mazda', value: '3', label: '3'},
  { link: 'mazda', value: '6', label: '6'},
  { link: 'mitsubishi', value: 'mirage', label: 'Mirage'},
  { link: 'mitsubishi', value: 'eclipse', label: 'Eclipse'},
  { link: 'mitsubishi', value: 'outlander', label: 'Outlander'},
  { link: 'mitsubishi', value: 'montero', label: 'Montero'},
  { link: 'mitsubishi', value: 'l200', label: 'L200'},
  { link: 'nissan', value: '370z', label: '370Z'},
  { link: 'nissan', value: 'sentra', label: 'Sentra'},
  { link: 'nissan', value: 'gt-r', label: 'GT-R'},
  { link: 'nissan', value: 'maxima', label: 'Maxima'},
  { link: 'nissan', value: 'altima', label: 'Altima'},
  { link: 'nissan', value: 'note', label: 'Note'},
  { link: 'nissan', value: 'versa', label: 'Versa'},
  { link: 'nissan', value: 'march', label: 'March'},
  { link: 'nissan', value: 'kicks', label: 'Kicks'},
  { link: 'nissan', value: 'murano', label: 'Murano'},
  { link: 'nissan', value: 'armada', label: 'Armada'},
  { link: 'nissan', value: 'x-trail', label: 'X-trail'},
  { link: 'nissan', value: 'pathfinder', label: 'Pathfinder'},
  { link: 'nissan', value: 'np300', label: 'NP300'},
  { link: 'nissan', value: 'leaf', label: 'Leaf'},
  { link: 'peugeot', value: '208', label: '208'},
  { link: 'peugeot', value: '301', label: '301'},
  { link: 'peugeot', value: '308', label: '308'},
  { link: 'peugeot', value: '2008', label: '2008'},
  { link: 'peugeot', value: '3008', label: '3008'},
  { link: 'peugeot', value: '5008', label: '5008'},
  { link: 'renault', value: 'stepway', label: 'Stepway'},
  { link: 'renault', value: 'logan', label: 'Logan'},
  { link: 'renault', value: 'duster', label: 'Duster'},
  { link: 'renault', value: 'captur', label: 'Captur'},
  { link: 'renault', value: 'koleos', label: 'Koleos'},
  { link: 'renault', value: 'twizy', label: 'Twizy'},
  { link: 'renault', value: 'oroch', label: 'Oroch'},
  { link: 'renault', value: 'sandero', label: 'Sandero'},
  { link: 'seat', value: 'ibiza', label: 'Ibiza'},
  { link: 'seat', value: 'toledo', label: 'Toledo'},
  { link: 'seat', value: 'león', label: 'León'},
  { link: 'seat', value: 'arona', label: 'Arona'},
  { link: 'seat', value: 'ateca', label: 'Ateca'},
  { link: 'subaru', value: 'XV', label: 'XV'},
  { link: 'subaru', value: 'Forester', label: 'Forester'},
  { link: 'subaru', value: 'WRX', label: 'WRX'},
  { link: 'subaru', value: 'STI', label: 'STI'},
  { link: 'subaru', value: 'BRZ', label: 'BRZ'},
  { link: 'suzuki', value: 'vitara', label: 'Vitara'},
  { link: 'suzuki', value: 'ciaz', label: 'Ciaz'},
  { link: 'suzuki', value: 'swift', label: 'Swift'},
  { link: 'suzuki', value: 's.cross', label: 'S.Cross'},
  { link: 'suzuki', value: 'ignis', label: 'Ignis'},
  { link: 'suzuki', value: 'ertiga', label: 'Ertiga'},
  { link: 'toyota', value: 'yaris', label: 'Yaris'},
  { link: 'toyota', value: 'corolla', label: 'Corolla'},
  { link: 'toyota', value: 'camry', label: 'Camry'},
  { link: 'toyota', value: 'prius', label: 'Prius'},
  { link: 'toyota', value: 'rav4', label: 'RAV4'},
  { link: 'toyota', value: 'avanza', label: 'Avanza'},
  { link: 'toyota', value: 'c-hr', label: 'C-HR'},
  { link: 'toyota', value: 'sienna', label: 'Sienna'},
  { link: 'toyota', value: 'highlander', label: 'Highlander'},
  { link: 'toyota', value: 'sequoia', label: 'Sequoia'},
  { link: 'toyota', value: 'hilux', label: 'Hilux'},
  { link: 'toyota', value: 'tacoma', label: 'Tacoma'},
  { link: 'toyota', value: 'tundra', label: 'Tundra'},
  { link: 'volkswagen', value: 'gol', label: 'Gol'},
  { link: 'volkswagen', value: 'polo', label: 'Polo'},
  { link: 'volkswagen', value: 'vento', label: 'Vento'},
  { link: 'volkswagen', value: 'jetta', label: 'Jetta'},
  { link: 'volkswagen', value: 'passat', label: 'Passat'},
  { link: 'volkswagen', value: 'beetle', label: 'Beetle'},
  { link: 'volkswagen', value: 'golf', label: 'Gold'},
  { link: 'volkswagen', value: 'tiguan', label: 'Tiguan'},
  { link: 'volkswagen', value: 'teramont', label: 'Teramont'},

];

export const vehicleTypeOptions = [
  {value: 'compacto', label: 'Compacto'},
  {value: 'sedan', label: 'Sedán'},
  {value: 'suv', label: 'SUV'},
  {value: 'hatchback', label: 'Hatchback'},
  {value: 'deportivo', label: 'Deportivo'},
  {value: 'van', label: 'Van'},
  {value: 'pickup', label: 'Pick Up'},
];