import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import asyncComponent from './Shared/AsyncComponent';
import Private from './Shared/Private';
import Privacy from '../components/Legal/Privacy';
import Terms from '../components/Legal/Terms';
import { logWithUserInformation, isUserLoggedIn } from '../helpers/functions';

const Home = asyncComponent(() => import('./Home').then(module => module.default));
const Navbar = asyncComponent(() => import('../containers/NavbarContainer').then(module => module.default));
const Login = asyncComponent(() => import('../containers/LoginContainer').then(module => module.default));
const Admin = asyncComponent(() => import('../containers/AdminContainer').then(module => module.default));
const Profile = asyncComponent(() => import('../containers/ProfileContainer').then(module => module.default));
const Results = asyncComponent(() => import('../components/Results').then(module => module.default));
const Trip = asyncComponent(() => import('../containers/TripContainer').then(module => module.default));

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      properties: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      open: true,
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    // e.preventDefault();
    this.setState({ open: false });
  }

  render() {
    const { properties } = this.state;

    return (
      <Provider store={this.props.store}>
        <div className="app">
          <BrowserRouter>
            <Fragment>
              { isUserLoggedIn() && logWithUserInformation() }
              <Navbar/>
              <Switch>
                <Route path="/" exact={true} component={Home}/>
                <Route path="/terms-and-conditions" component={Terms}/>
                <Route path="/privacy-policies" component={Privacy}/>
                <Route path="/results" render={props => (<Results {...props} mobile={properties}/>)}/>
                <Route path="/login" render={(props) => {
                  return isUserLoggedIn() ? <Redirect to="/admin?validation"/> : <Login {...props}/>;
                }}/>
                <Route path="/logout" render={() => {
                  this.props.logout();
                  return <Redirect to="/"/>
                }}/>
                <Private path="/admin" component={Admin}/>
                <Private path={["/profile", "/user"]} component={Profile} mobile={properties}/>
                <Private path="/trip" component={Trip} mobile={properties}/>
                <Redirect to="/"/> {/* TODO: Change to a 404 page */}
              </Switch>
            </Fragment>
          </BrowserRouter>
        </div>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  logout: PropTypes.func,
};

export default App;
